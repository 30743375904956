import * as React from "react";

// React
import { useState, useEffect } from "react";
import { AdvancedImage, responsive } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { Link } from "gatsby";

// SVG
import Logo from "../../images/logo/logo.inline.svg";
import LogoLetters from "../../images/logo/logo-letters.inline.svg";
import AppleLogo from "../../images/players/apple.inline.svg";
import PlayLogo from "../../images/players/google-play.inline.svg";

// Heroicons
import { ArrowNarrowDownIcon } from "@heroicons/react/outline";

// Shared Components
import TextModal from "../../components/shared/modal/Modal";
import LoadingButton from "../../components/shared/loading-button/LoadingButton";
import Footer from "../../components/shared/footer/Footer";
import Header from "../../components/shared/header/Header";
import { Controller, Scene } from "react-scrollmagic";
import Seo from "../../components/seo";
import CoachesCarousel from "../../components/shared/coaches-carousel/CoachesCarousel";

// Services
import { contactSubmission } from "../../services/contactAPI";

// Animation
import ModuleAnimation from "../../images/animation/ModuleAnimation";

export default function Players() {
  const [largeScreen, setLargeScreen] = useState(true);
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [icon, setIcon] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  //ADD LISTENER TO CHECK THE SCREEN SIZE
  function checkScreenSize() {
    if (window.innerWidth <= 768) {
      setLargeScreen(false);
    } else {
      setLargeScreen(true);
    }
  }

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "kirkwork",
    },
  });
  // define our cloudinary images
  const HeaderImg = cld
    .image("v1646405338/Aritae/Players/players-hero_hisggy.jpg")
    .quality("auto");
  const HeaderMobile = cld
    .image("v1646405337/Aritae/Players/players-hero-mobile_fkxu45.jpg")
    .quality("auto");
  const SelfAware_2_1 = cld
    .image(
      "v1649095072/Aritae/Players/soccer-player-bouncing-soccer-ball-in-air_2_1_fsouxb.jpg"
    )
    .quality("auto");
  const SelfAware_1_2 = cld
    .image(
      "v1649095072/Aritae/Players/soccer-player-bouncing-soccer-ball-in-air_1_2_gwzxa5.jpg"
    )
    .quality("auto");
  const Focused_2_1 = cld
    .image(
      "v1648056806/Aritae/Players/players-boy-playing-basketball_2_1_tndydq.jpg"
    )
    .quality("auto");
  const Focused_1_2 = cld
    .image(
      "v1648056805/Aritae/Players/players-boy-playing-basketball_1_2_pto8iq.jpg"
    )
    .quality("auto");
  const HockeyPlayers = cld
    .image(
      "v1648057037/Aritae/Players/players-hockey-players-gameplan_f2meac.jpg"
    )
    .quality("auto");
  const Strategic_2_1 = cld
    .image(
      "v1650566610/Aritae/Players/aritae-coach-climbing-sport-route-hard_xaiyho.jpg"
    )
    .quality("auto");
  const Strategic_1_2 = cld
    .image(
      "v1650566610/Aritae/Players/aritae-coach-climbing-sport-route-tall_aflsxq.jpg"
    )
    .quality("auto");
  const Confident_2_1 = cld
    .image(
      "v1649094518/Aritae/Players/theater-actor-on-stage-finishing-up-a-play-performance_2_1_u0gwn3.jpg"
    )
    .quality("auto");
  const Confident_1_2 = cld
    .image(
      "v1649094518/Aritae/Players/theater-actor-on-stage-finishing-up-a-play-performance_1_2_z9fhmb.jpg"
    )
    .quality("auto");
  const Inspired_2_1 = cld
    .image(
      "v1649094835/Aritae/Players/lacrosse-kid-running-triumphantly-with-teamates_2_1_nhuesd.jpg"
    )
    .quality("auto");
  const Inspired_1_2 = cld
    .image(
      "v1649094835/Aritae/Players/lacrosse-kid-running-triumphantly-with-teamates_1_2_ltb5il.jpg"
    )
    .quality("auto");

  const CoachWalkingWithPlayer = cld
    .image("v1649096057/Aritae/Players/coach_walking_with_player.jpg")
    .quality("auto");
  const MobileExample = cld
    .image("v1646071168/Aritae/Players/about-phones-example_bpnjoi.png")
    .quality("auto");

  const SignUpBanner = cld
    .image(
      "v1650565770/Aritae/Players/Aritae-Mosiac-of-Players-large_o9umzp.jpg"
    )
    .quality("auto");
  const SignUpBannerMobile = cld
    .image(
      "v1650565770/Aritae/Players/Aritae-Mosiac-of-Players-small_jctfjs.jpg"
    )
    .quality("auto");

  //email sent from contact form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    let postBody = JSON.stringify({
      Email: email,
      PageName: "Players",
    });

    contactSubmission(postBody).then(async (response) => {
      const data = await response;
      if (data.dbSuccess === true && data.endUserEmailSuccess === true) {
        // modal success
        setIsOpen(true);
        setIcon("success");
        setDescription(
          "Thanks for your interest in ARITAE! Please check your inbox for updates."
        );
        setLoading(false);
        event.target.reset();
      } else {
        // modal error
        setIsOpen(true);
        setIcon("failure");
        setDescription("Something went wrong. Please try again.");
        setLoading(false);
        event.target.reset();
      }
    });
  };

  // Modal
  function toggleModalOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <div id="root">
      <Header />
      <Seo
        title="Players"
        description="Learn what ARITAE Self-Leadership Academy can do for you."
      />
      <div className="relative bg-gray-100">
        {/*Modal */}
        <TextModal
          isOpen={isOpen}
          close={toggleModalOpen}
          icon={icon}
          description={description}
        />
        <Controller>
          {/* START HERO SECTION */}
          <div className="h-screen relative">
            <div className="absolute inset-0">
              <div className="absolute flex-1 top-0 inset-0">
                <div className={"absolute inset-0 opacity-50 bg-stone-500"} />

                <AdvancedImage
                  plugins={[responsive({ steps: 200 })]}
                  cldImg={largeScreen ? HeaderImg : HeaderMobile}
                  alt="young woman doing exercise outside on a field"
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
            <div className="relative">
              <div className="flex flex-col h-screen items-center justify-center">
                <h1 className="text-center text-4xl font-extrabold sm:text-5xl lg:text-6xl">
                  <span className="block text-white text-2xl pb-6">
                    PLAYERS
                  </span>
                </h1>
                <div
                  className={"flex flex-col items-center justify-center my-12"}
                >
                  <Logo fill={"white"} className={"h-32 w-auto mx-auto pb-2"} />
                  <LogoLetters
                    fill={"white"}
                    className={"h-auto w-48 mx-auto"}
                  />
                </div>
                <p className={"text-white text-center font-bold text-4xl mt-4"}>
                  CLARITY. FOCUS. POWER.
                </p>
                <div className="hidden lg:flex mt-10 max-w-sm mx-auto justify-center">
                  <ArrowNarrowDownIcon className={"h-7 text-white"} />
                </div>
              </div>
            </div>
          </div>
          {/* END HERO SECTION */}

          {/* START CLARITY FOCUS POWER */}
          <div className="">
            <div className={"max-w-7xl mx-auto py-12 sm:my-24 px-16"}>
              <div
                className={"relative grid grid-cols-1 lg:grid-cols-2 gap-10"}
              >
                {/* First section */}
                <div
                  className={
                    "relative h-full w-full max-w-sm md:max-w-lg lg:max-w-none mx-auto"
                  }
                >
                  <div className={"relative h-full w-full"}>
                    <div
                      className={
                        "absolute inset-3 -right-3 -bottom-3 bg-slate-600"
                      }
                    />
                    <div className={"absolute inset-0 z-20"}>
                      <h1 className="absolute p-5 text-white text-md md:text-3xl bottom-0 font-extrabold">
                        ARITAE ACADEMY BELIEVES YOU HAVE TO LEARN TO LEAD
                        YOURSELF BEFORE YOU CAN LEAD OTHERS.
                      </h1>
                    </div>
                    <div
                      className={
                        "relative h-full w-full justify-center items-center"
                      }
                    >
                      <AdvancedImage
                        plugins={[responsive(200)]}
                        cldImg={HockeyPlayers}
                        alt="hockey coach shows players a plan on a small whiteboard in the rink"
                        className="h-full w-full object-cover object-top aspect-square"
                      />
                      <div
                        className={"absolute inset-0 opacity-50 bg-stone-500"}
                      />
                    </div>
                  </div>
                </div>
                {/* Second section */}
                <div
                  className={
                    "relative flex justify-center items-center max-w-lg mx-auto"
                  }
                >
                  <div className="lg:py-24">
                    <h1 className=" text-left lg:text-4xl font-extrabold tracking-tight sm:text-2xl">
                      <span className="block text-slate-600 uppercase">
                        clarity. focus. power.
                      </span>
                    </h1>
                    <br />
                    <p className="text-left font-serif text-black md:text-md lg:text-xl">
                      These Components are needed to Unleash your potential,
                      become a self-leader, and live a life filled with love,
                      happiness, significance, and success. With the guidance
                      and inspiration from your ARITAE Coach, you will create
                      the framework for your future.
                    </p>
                    <div className={"flex flex-row items-center pt-5"}>
                      <div className={"h-1 w-5 bg-black mr-5"} />
                      <span>
                        <p
                          className={
                            "text-black font-bold text-md md:text-xl max-w-2xl"
                          }
                        >
                          JERRY MORRISSEY
                        </p>
                        <p
                          className={
                            "text-mustard font-bold text-md md:text-xl max-w-2xl"
                          }
                        >
                          FOUNDER, CEO & CHIEF INSPIRATIONAL OFFICER
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* END CLARITY FOCUS POWER */}

          {/* START 5 Image Section */}
          <div className="bg-mustard bg-opacity-10">
            <div className={"relative py-24 px-16"}>
              <div className={"pb-8"}>
                <div className={"flex items-center"}>
                  <h1
                    className={
                      "mx-auto text-slate-600 max-w-xl text-center text-4xl"
                    }
                  >
                    AN ARITAE <span className="font-extrabold">PLAYER IS</span>
                  </h1>
                </div>
              </div>
              {/* TODO TODO need to make a whole testimonials page. */}
              <div className={"flex flex-row justify-center gap-10"}>
                <a
                  href={"/testimonials"}
                  className={
                    "flex flex-row items-center pt-4 gap-5 hover:gap-3"
                  }
                >
                  <p
                    className={
                      "text-slate-600 font-bold underline underline-offset-4 decoration-2 decoration-slate-600 hover:underline-offset-2"
                    }
                  >
                    Read about how ARITAE has helped players
                  </p>
                </a>
              </div>
            </div>

            <div
              className={
                "relative mx-auto grid grid-cols-1 md:grid-cols-5 gap-3 max-w-sm md:max-w-7xl"
              }
            >
              {/* First Grid Item */}

              <div className={"relative"}>
                <div
                  className={
                    "aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2"
                  }
                >
                  <div className="aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2">
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={largeScreen ? Inspired_1_2 : Inspired_2_1}
                      alt="young girl playing lacrosse and running down field with support of her teamates."
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className={"absolute inset-0 opacity-50 bg-stone-500"} />
                  <div>
                    <h1
                      className={
                        "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center text-xl md:text-sm lg:text-xl font-bold"
                      }
                    >
                      INSPIRED
                    </h1>
                  </div>
                </div>
              </div>

              <div className={"relative"}>
                <div
                  className={
                    "aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2"
                  }
                >
                  <div className="aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2">
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={largeScreen ? Confident_1_2 : Confident_2_1}
                      alt="theater kids finishing performance on stage with a large smile on their faces"
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className={"absolute inset-0 opacity-50 bg-stone-500"} />
                  <div>
                    <h1
                      className={
                        "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center text-xl md:text-sm lg:text-xl font-bold"
                      }
                    >
                      CONFIDENT
                    </h1>
                  </div>
                </div>
              </div>

              {/* Third Grid Item */}
              <div className={"relative"}>
                <div
                  className={
                    "aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2"
                  }
                >
                  <div className="aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2">
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={largeScreen ? Focused_1_2 : Focused_2_1}
                      alt="young man runs on basketball court with ball, while another watches from the sidelines"
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className={"absolute inset-0 opacity-50 bg-stone-500"} />
                  <div>
                    <h1
                      className={
                        "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center text-xl md:text-sm lg:text-xl font-bold"
                      }
                    >
                      FOCUSED
                    </h1>
                  </div>
                </div>
              </div>
              {/* Fourth Grid Item */}
              <div className={"relative"}>
                <div
                  className={
                    "aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2"
                  }
                >
                  <div className="aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2">
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={largeScreen ? Strategic_1_2 : Strategic_2_1}
                      alt="margo hayes rock climbing on the side of a cliff in competition"
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className={"absolute inset-0 opacity-50 bg-stone-500"} />
                  <div>
                    <h1
                      className={
                        "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center text-xl md:text-sm lg:text-xl font-bold"
                      }
                    >
                      STRATEGIC
                    </h1>
                  </div>
                </div>
              </div>
              {/* Fith Grid Item */}
              <div className={"relative"}>
                <div
                  className={
                    "aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2"
                  }
                >
                  <div className="aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2">
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={largeScreen ? SelfAware_1_2 : SelfAware_2_1}
                      alt="Boy intently focused on soccer ball and kicking it in the air."
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className={"absolute inset-0 opacity-50 bg-stone-500"} />
                  <div>
                    <h1
                      className={
                        "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center text-xl md:text-sm lg:text-xl font-bold"
                      }
                    >
                      SELF-AWARE
                    </h1>
                  </div>
                </div>
              </div>
              {/* Done with Grid Items */}
            </div>
          </div>

          {/* END 5 Image Section */}

          {/* START ARITAE PLAYERS ARE */}
          <div
            className={
              "grid grid-cols-1 md:grid-cols-2 gap-10 px-16 max-w-7xl mx-auto mt-24 mb-32"
            }
          >
            {/* First section */}
            <Scene
              duration={largeScreen ? 700 : 0}
              pin={{ pushFollowers: true }}
              triggerHook={0}
              offset={-24}
              enabled={largeScreen}
            >
              <div className="bg-slate-600 p-10 space-y-5">
                <h1 className="text-white text-4xl">
                  ARITAE <span className="font-extrabold">PLAYERS ARE</span>
                </h1>
              </div>
            </Scene>

            {/* Second section */}
            <Scene
              duration={1000}
              triggerHook={0}
              offset={0}
              enabled={largeScreen}
            >
              {(progress) => (
                <div className={"relative"}>
                  <div
                    className={
                      "flex flex-col max-w-sm sm:ml-10 mt-10 space-y-12 md:space-y-24 "
                    }
                  >
                    <h1 className="text-left text-4xl font-extrabold tracking-tight">
                      <span className="block text-slate-600">ARTISTS</span>
                    </h1>
                    <h1 className="text-left text-4xl font-extrabold tracking-tight">
                      <span className="block text-slate-600">STUDENTS</span>
                    </h1>
                    <h1 className="text-left text-4xl font-extrabold tracking-tight">
                      <span className="block text-slate-600">ATHLETES</span>
                    </h1>
                    <h1 className="text-left text-4xl font-extrabold tracking-tight">
                      <span className="block text-slate-600">MUSICIANS</span>
                    </h1>
                    <h1 className="text-left text-4xl font-extrabold tracking-tight">
                      <span className="block text-slate-600">PERFORMERS</span>
                    </h1>
                    <h1 className="text-left text-4xl font-extrabold tracking-tight">
                      <span className="block text-slate-600">
                        ANYONE WHO DESIRES SUCCESS
                      </span>
                    </h1>
                  </div>
                </div>
              )}
            </Scene>
          </div>
          {/* END ARITAE PLAYERS ARE */}

          {/* START YOUR ARITAE COACH WILL */}
          <div
            className={
              "grid grid-cols-1 md:grid-cols-2 gap-10 px-16 max-w-7xl mx-auto my-24"
            }
          >
            {/* First section */}

            <Scene
              duration={largeScreen ? 1200 : 0}
              triggerHook={0}
              offset={0}
              enabled={largeScreen}
            >
              {(progress) => (
                <div className={"relative order-last sm:order-first"}>
                  <div
                    className={
                      "flex flex-col max-w-sm sm:ml-10 mt-10 space-y-12 md:space-y-24"
                    }
                  >
                    <h1 className="text-right text-2xl font-extrabold tracking-tight">
                      <span className="block text-slate-600 uppercase">
                        Inspire you to unleash your potential.
                      </span>
                    </h1>
                    <h1 className="text-right text-2xl font-extrabold tracking-tight">
                      <span className="block text-slate-600 uppercase">
                        Guide you to discover a deeper sense of self awareness.
                      </span>
                    </h1>
                    <h1 className="text-right text-2xl font-extrabold tracking-tight">
                      <span className="block text-slate-600 uppercase">
                        Increase your emotional and social intelligence.
                      </span>
                    </h1>
                    <h1 className="text-right text-2xl font-extrabold tracking-tight">
                      <span className="block text-slate-600 uppercase">
                        Show you how to gain clarity around who you are today
                        ,what you want in the future, and why you want what you
                        want.
                      </span>
                    </h1>
                    <h1 className="text-right text-2xl font-extrabold tracking-tight">
                      <span className="block text-slate-600 uppercase">
                        Guide you to stay on track and create MicroPlans to
                        achieve your goals.
                      </span>
                    </h1>
                    <h1 className="text-right text-2xl font-extrabold tracking-tight">
                      <span className="block text-slate-600 uppercase">
                        Create actionable goals based on what you learned in the
                        discovery module.{" "}
                      </span>
                    </h1>
                    <h1 className="text-right text-2xl font-extrabold tracking-tight">
                      <span className="block text-slate-600 uppercase">
                        Help keep you on track
                      </span>
                    </h1>
                    <h1 className="text-right text-2xl font-extrabold tracking-tight">
                      <span className="block text-slate-600 uppercase">
                        Teach you how to formulate MicroPlans by identifying the
                        actions, obstacles and solutions required to achieve
                        your goals.
                      </span>
                    </h1>
                    <h1 className="text-right text-2xl font-extrabold tracking-tight">
                      <span className="block text-slate-600 uppercase">
                        Work with you to formalize a personal plan for all areas
                        of your life
                      </span>
                    </h1>
                  </div>
                </div>
              )}
            </Scene>

            {/* Second section */}

            <Scene
              duration={largeScreen ? 1300 : 0}
              pin={{ pushFollowers: true }}
              triggerHook={0}
              offset={-24}
              enabled={largeScreen}
            >
              <div className={"relative"}>
                <div className="relative h-full w-full">
                  <div
                    className={"absolute inset-0 z-10 opacity-75 bg-stone-500"}
                  />
                  <div
                    className={
                      "absolute inset-3 -right-3 -bottom-3 bg-slate-600"
                    }
                  />
                  <div className={"absolute inset-0 z-20"}>
                    <h1 className="absolute p-5 text-white text-4xl lg:text-6xl bottom-0 font-extrabold">
                      YOUR ARITAE COACH WILL
                    </h1>
                  </div>
                  <AdvancedImage
                    plugins={[responsive({ steps: 200 })]}
                    cldImg={CoachWalkingWithPlayer}
                    alt="Football coach walking with player and showing leadership."
                    className="relative h-full w-full object-cover"
                  />
                </div>
              </div>
            </Scene>
          </div>
          {/* END YOUR ARITAE COACH WILL */}

          {/* START OUR COACHES */}
          <div className="relative">
            <div className={"relative mt-24 md:py-24 max-w-7xl mx-auto"}>
              <div className={"pb-12"}>
                <div className={"flex justify-center items-center"}>
                  <Link to="/academy/coaches">
                    <div className="text-slate-600 hover:text-mustard hover:opacity-90 text-4xl text-center">
                      <h1 className="animate-bounce">
                        MEET THE{" "}
                        <span className="font-extrabold">ARITAE COACHES</span>
                      </h1>
                    </div>
                  </Link>
                </div>
              </div>
              {/* Carousel */}
              <div className="relative max-h-md">
                <CoachesCarousel />
              </div>
            </div>
          </div>
          {/* END OUR COACHES */}

          {/* START Animation */}
          <div className="bg-mustard bg-opacity-10">
            <div className="mx-auto py-24 px-4 md:px-16 max-w-7xl ">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-24">
                <div className="flex flex-col justify-center">
                  <h2
                    className={
                      "text-slate-600 text-left text-2xl font-bold uppercase"
                    }
                  >
                    THE ARITAE SELF-LEADERSHIP ACADEMY UTILITIZES THE
                    <span className="underline decoration-mustard decoration-4 underline-offset-4">
                      {" "}
                      5 module{" "}
                    </span>
                    ARITAE SELF-LEADERSHIP PROGRAM AND THE ARITAE COACH TO GUIDE
                    AND INSPIRE EACH PLAYER TOWARD A LIFE FILLED WITH LOVE,
                    HAPPINESS, SIGNIFICANCE AND SUCCESS.
                  </h2>
                  <div className="flex flex-col items-start pt-5 space-y-3 max-w-md">
                    <div className="flex flex-row space-x-5">
                      <p className="font-bold text-2xl text-navy">01</p>
                      <p className="font-serif">
                        <strong>Discovery.</strong> Deep thought and
                        self-reflection about your life are the foundation for
                        all to come. You will be guided toward a deeper sense of
                        self-awareness and help you get in touch with what is
                        real in your heart.
                      </p>
                    </div>
                    <div className="flex flex-row space-x-5">
                      <p className="font-bold text-2xl text-mustard">02</p>
                      <p className="font-serif">
                        <strong>Focus.</strong> Use what you discovered to
                        formulate your vision and purpose of{" "}
                        <strong>who</strong> you are today,{" "}
                        <strong>what</strong> you want in the future, and a
                        better understanding of your <strong>why</strong>.
                      </p>
                    </div>
                    <div className="flex flex-row space-x-5">
                      <p className="font-bold text-2xl text-maroon">03</p>
                      <p className="font-serif">
                        <strong>Action.</strong> Formalize your set of goals
                        that are in alignment with your stated purpose and
                        vision.
                      </p>
                    </div>
                    <div className="flex flex-row space-x-5">
                      <p className="font-bold text-2xl text-denim">04</p>
                      <p className="font-serif">
                        <strong>Strategy.</strong> Design <strong>how</strong>{" "}
                        you will accomplish each of your stated goals with
                        ARITAE Microplans&trade;.
                      </p>
                    </div>
                    <div className="flex flex-row space-x-5">
                      <p className="font-bold text-2xl text-forest">05</p>
                      <p className="font-serif">
                        <strong>Personal Plan.</strong> Organize the information
                        you discovered and work with your coach to create your
                        Personal Plan to accomplish your goals and find love,
                        happiness, significance and success.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center order-first lg:order-last">
                  <div className="max-w-xl">
                    <ModuleAnimation />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END Animation */}

          {/* START ARITAE MOBILE */}
          <div className="bg-mustard bg-opacity-10">
            <div className={"max-w-7xl mx-auto py-24 px-16 "}>
              <div
                className={"relative grid grid-cols-1 md:grid-cols-2 gap-10"}
              >
                {/* First section */}
                <div
                  className={
                    "relative order-last flex justify-center items-center"
                  }
                >
                  <div>
                    <h1 className="text-left text-4xl font-extrabold tracking-tight">
                      <span className="block text-slate-600 uppercase">
                        ARITAE mobile - inspiration in the palm of your hand
                      </span>
                    </h1>
                    <br />
                    <p className="text-left font-serif text-black md:text-md lg:text-xl">
                      At the ARITAE Self-Leadership Academy we utilize a full
                      featured mobile application for the player to communicate
                      with their ARITAE coach, interact with the ARITAE
                      community and complete the ARITAE program
                    </p>
                    <div className="flex flex-row mt-20 justify-start">
                      <div className={""}>
                        <Link
                          to="/coming-soon"
                          className="items-center w-50 h-16 mr-5 inline-flex bg-black bg-origin-border px-6 py-4 border border-transparent text-base font-medium rounded-md shadow-sm text-white"
                        >
                          <AppleLogo className={"w-7 h-7 fill-white mr-2"} />
                          App Store
                        </Link>
                      </div>
                      <div>
                        <Link
                          to="/coming-soon"
                          className="items-center w-50 h-16 inline-flex bg-black bg-origin-border px-6 py-4 border border-transparent text-base font-medium rounded-md shadow-sm text-white"
                        >
                          <PlayLogo className={"w-7 h-7 fill-white mr-2"} />
                          Google Play
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Second section */}
                <div className={"relative flex-1"}>
                  <div className="relative h-full w-full items-center justify-center">
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={MobileExample}
                      alt="two cell phones showing different screens of the aritae mobile app"
                      className="relative w-full h-full object-scale-down"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* END ARITAE MOBILE */}

          {/* START Sign Up Form */}
          <div className={"flex h-screen justify-center items-center"}>
            <div className="absolute z-10 flex h-screen w-screen overflow-hidden">
              <div className={"absolute inset-0 opacity-75 bg-stone-500"} />
              <AdvancedImage
                plugins={[responsive({ steps: 200 })]}
                cldImg={largeScreen ? SignUpBanner : SignUpBannerMobile}
                alt="collage of people doing sports, arts, music, nature, and academics"
                className="w-full h-full object-cover"
              />
            </div>

            <div className="relative z-20 mx-auto max-w-3xl text-center md:px-5">
              <div className="py-10">
                <h4 className="text-4xl font-extrabold text-white">
                  ARE YOU READY TO GET STARTED?
                </h4>
              </div>

              <form
                className="space-y-6 row-auto py-5 p-4  max-w-3xl xs:m-12 md:m-0"
                action="#"
                method="POST"
                onSubmit={handleSubmit}
              >
                <div className="mx-auto">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-between">
                    <div className="mt-1 md:col-span-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        placeholder="Email"
                        required
                        className="text-center md:text-left w-full px-5 py-3 text-gray-500 border-transparent placeholder-gray-500 bg-gray-100 font-sm md:font-md"
                      />
                    </div>

                    <div className="mt-1">
                      {loading ? (
                        <LoadingButton type={"email"} />
                      ) : (
                        <button
                          type="submit"
                          className="w-full flex justify-center px-5 py-3 border border-transparent rounded shadow-sm text-sm text-white font-bold bg-black hover:bg-gray-800"
                        >
                          SIGN UP
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* END Sign Up Form */}
        </Controller>
      </div>
      <Footer />
    </div>
  );
}
